import React from "react";
import {
  FaTwitter,
  FaGithub,
  FaMedium,
  FaFacebook,
  FaInstagram,
  FaCopyright
} from "react-icons/fa";
import { StaticQuery, graphql } from "gatsby";
import "./style.scss";
import Emoji from "./emoji";
import moment from "moment";
import { Link } from 'gatsby';
// const Footer = () => (
// 	<StaticQuery
// 		query={graphql`
// 			query SocialQuery {
// 				site {
// 					siteMetadata {
// 						gatsby
// 						bulma
// 						twitter
// 						medium
// 						github
// 					}
// 				}
// 			}
// 		`}
// 		render={data => (
// 			<footer className="footer center">
// 				<div className="content has-text-centered">
// 					<p className="is-size-4">
// 						This website was handcrafted with plenty cups of{' '}
// 						<Emoji emoji="☕" />
// 					</p>
// 					<p className="is-size-4">
// 						By Aman Mittal (@amahimself) using{' '}
// 						<a href={data.site.siteMetadata.gatsby}>Gatsby</a> +{' '}
// 						<a href={data.site.siteMetadata.bulma}>Bulma</a>
// 					</p>
// 					<article className="media center">
// 						<span className="icon">
// 							<a href={data.site.siteMetadata.twitter}>
// 								<FaTwitter size="fa-2x" color="blue" />
// 							</a>
// 						</span>
// 						&nbsp;
// 						<span className="icon">
// 							<a href={data.site.siteMetadata.github}>
// 								<FaGithub size="fa-2x" color="black" />
// 							</a>
// 						</span>
// 						&nbsp;
// 						<span className="icon">
// 							<a href={data.site.siteMetadata.medium}>
// 								<FaMedium size="fa-2x" color="green" />
// 							</a>
// 						</span>
// 						&nbsp;
// 					</article>
// 					&nbsp;
// 					<p className="is-size-5">
// 						You can also back or support this project for me to keep it updated
// 						by{' '}
// 						<a href="https://www.paypal.me/amanhimself/2">Buying Me a Coffee</a>
// 					</p>
// 				</div>
// 			</footer>
// 		)}
// 	/>
// );

const Footer = () => {
  return (
    <footer className="footer">
      <section className="section">
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-12">
              <Link
                style={{ fontSize: 35, color: "white", marginBottom: 10 }}
                className="brand-font white"
              >
                innovate
              </Link>
            </div>
            <div className="column is-8">
              <h3 className="title">Pages</h3>
              <ul>
                <li>About us</li>
                <li>What we do</li>
                <li>Privacy Policy</li>
                <li>Site Map</li>
              </ul>
            </div>
            <div className="column is-4">
              <h3 className="title">Social Media</h3>
              <ul>
                <li>
                  <FaFacebook /> Facebook
                </li>
                <li>
                  <FaInstagram /> Instagram
                </li>
              </ul>
            </div>
          </div>
          <FaCopyright /> Innovate Web Solutions {moment().format("YYYY")}
        </div>
      </section>
    </footer>
  );
};

export default Footer;
