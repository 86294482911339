import React, { useState } from "react";
import { FaGithub } from "react-icons/fa";

import "./style.scss";
import gatsbyLogo from "../images/gatsby-icon.png";
import bulmaLogo from "../images/bulma-logo.png";
import { Link } from "gatsby";
import { min } from "moment";

const Navbar = () => {
  // <div className="hero-head sticky-nav">
  // 	<nav className="navbar">
  // 		<div className="container">
  // 			<div className="navbar-brand">
  // 				<a
  // 					className="brand-font navbar-item primary-text"
  // 					href="/"
  // 					style={{fontSize: 30}}
  // 				>
  // 				innovate
  // 				</a>
  // 			</div>
  // 			<div id="navbarMenuHeroA" className="navbar-menu">
  // 				<div className="navbar-end">
  // 				<span className="navbar-item">
  // 						<Link to="/websites">Web</Link>
  // 					</span>
  // 					<span className="navbar-item">
  // 						<Link to="/apps">App</Link>
  // 					</span>
  // 					<span className="navbar-item">
  // 						<Link to="/digital-marketing">Marketing</Link>
  // 					</span>
  // 					<span className="navbar-item">
  // 						<Link to="/about">About</Link>
  // 					</span>
  // 				</div>
  // 			</div>
  // 		</div>
  // 	</nav>
  // </div>

  const [mobileNavActive, updateMobileNavActive] = useState(false);

  return (
    <nav
      className="navbar is-fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <a
          className="brand-font navbar-item black"
          href="/"
          style={{ fontSize: 25, marginRight: 15 }}
        >
          innovate
        </a>

        <a
          role="button"
          className="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={() => updateMobileNavActive(!mobileNavActive)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        id="navbarBasicExample"
        className={`navbar-menu ${mobileNavActive ? `is-active` : null}`}
      >
        <div className="navbar-start">
          <Link className="navbar-item" to="/websites">Websites</Link>

          <Link className="navbar-item" to="/apps">Apps</Link>

          <Link className="navbar-item" to="/digital-marketing">Marketing</Link>

          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">More</a>

            <div className="navbar-dropdown">
              <Link className="navbar-item" to="/about">About</Link>
              <Link className="navbar-item" to="/contact">Contact</Link>
              <hr className="navbar-divider" />
              <Link className="navbar-item" to="">Report an issue</Link>
            </div>
          </div>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <a className="button is-light"><strong>Log in</strong></a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
