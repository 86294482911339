import React from "react";

const Hero = ({ size = "medium", color = "gradient", content = null }) => {
  return (
    <section class={`hero is-${size} is-${color}`}>
      <div class="hero-body">
        <section className="section">
          <div class="container">{content}</div>
        </section>
      </div>
    </section>
  );
};

export default Hero;
