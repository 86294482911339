import React from "react";
import Helmet from "./helmet";
import Navbar from "../components/navbar";
import Footer from "../components/footer";

const PageTemplate = ({
  title = null,
  metaName = null,
  metaDescription = null,
  children = null
}) => {
  return (
    <div>
      <Helmet>
        {title != null ? <title>title</title> : null}
        {metaName != null ? (
          <meta name={metaName} content={metaDescription} />
        ) : null}
      </Helmet>
      <Navbar />
      {children}
      <Footer />
    </div>
  );
};

export default PageTemplate;
